import React from "react";
import { Row, Col, Card, Collapse, Button } from 'antd';
import { Icon } from "@iconify/react";
import card1 from "../../assets/images/card1.png";
import card2 from "../../assets/images/card2.png";
import card3 from "../../assets/images/card3.png";
import card4 from "../../assets/images/card4.png";
import AivolFooter from "../../assets/chatdash/aivolfooter.svg";
import maskgrp1 from "../../assets/images/Maskgroup1.png"
import maskgrp2 from "../../assets/images/Maskgroup2.png"
import FooterImg from "../../assets/chatdash/Footer 1.svg";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Section7 from "./Section7";

function Section6() {
  const navigate = useNavigate();

  // const HandleDemoClick = () => {
  //   console.log("click contact")
  //   // navigate('/contact')
  //   navigate('/contact');
  // }

  const HandleDemoClick = () => {
    console.log("click contact");
    window.scrollTo({ top: 0, behavior: 'smooth' }); 
    navigate('/contact');
  };
  return (
    <>

      {/* <div className="product-scroll-container mt-2">
        <Row gutter={[24, 24]} className="horizontal-scroll">
        <Col xs={24} sm={24} md={6} lg={7} xl={7} className="product-h">
        <div className="p-6 position-relative">
  <div className="position-absolute top-0 end-0 sidearrow">
    <Icon icon="solar:alt-arrow-right-linear" width="2em" height="3em" />
  </div>
  <div className="d-flex flex-column justify-content-center align-items-center">
    <img src={card1} alt="" />
    <div className="blur">
    <p className="margin-blur"><b>Beyond the Hype: </b>Real-World Applications of Gen AI Technology</p>
    </div>
  </div>
</div>


        </Col>
        <Col xs={24} sm={24} md={6} lg={7} xl={7} className="product-h">
        <div className="p-6 position-relative">
  <div className="position-absolute top-0 end-0 sidearrow">
    <Icon icon="solar:alt-arrow-right-linear" width="2em" height="3em" />
  </div>
  <div className="d-flex flex-column justify-content-center align-items-center">
    <img src={card2} alt="" />
    <div className="blur">
    <p className="margin-blur"><b>The Rise of Gen AI: </b>How AI is Reshaping Industries and Society</p>
    </div>
  </div>
</div>
        </Col>
        <Col xs={24} sm={24} md={6} lg={7} xl={7} className="product-h">
        <div className="p-6 position-relative">
  <div className="position-absolute top-0 end-0 sidearrow">
    <Icon icon="solar:alt-arrow-right-linear" width="2em" height="3em" />
  </div>
  <div className="d-flex flex-column justify-content-center align-items-center">
    <img src={card3} alt="" />
    <div className="blur">
    <p className="margin-blur"><b>Tech for Good: </b> How Technology is Driving Social Change and Sustainability</p>
    </div>
  </div>
</div>


        </Col>
        <Col xs={24} sm={24} md={6} lg={7} xl={7} className="product-h">
        <div className="p-6 position-relative">
  <div className="position-absolute top-0 end-0 sidearrow">
    <Icon icon="solar:alt-arrow-right-linear" width="2em" height="3em" />
  </div>
  <div className="d-flex flex-column justify-content-center align-items-center">
    <img src={card1} alt="" />
    <div className="blur">
    <p className="margin-blur"><b>Cybersecurity in the Digital: </b>Your Data in a  Connected Wo</p>
    </div>
  </div>
</div>

        </Col> 
      </Row>
      </div> */}
      <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-4">
        <section className="mt-10 relative margin-top-img">
          {/* <img src={maskgrp1} alt="" className="maskgrp1"/>
      <img src={maskgrp2} alt="" className="maskgrp2"/> */}
          {/* <img src={AivolFooter} alt="" className="aivolvefoot" /> */}

          <div className="container ">
            <div className="bottom-box text-center">
              <p className="m-0 foot-cs text-gradient aivolve-foot ">AIVolveX</p>
              <p className="m-0 foot-cs1 solved">has arrived</p>
              <div>
                <p className="ready">Ready to transform your business with Generative AI? <br></br>
                  Lets create magic together!</p>
              </div>
              <div className="d-flex justify-content-center mt-4">
                <button class="bottom-box-btn flex items-center"
                onClick={HandleDemoClick}
                // to="/contact"
                >
                  <span className="">
                    <Icon icon="solar:alt-arrow-right-linear" />
                  </span>
                  <span>Schedule A Demo</span>              
                </button>
              </div>
            </div>

          </div>
        </section>

        <div className="absolute footin">
          <img
            src={FooterImg}
            //   className="w-full h-auto opacity-30"
            // class="robo-background"
            alt="Chat Background"
          />
        </div>
        <div className="margin-foot">
          <Section7 />
        </div>
      </Col>
    </>

  )
}
export default Section6;