import React, { useRef, useState } from "react";
import { Row, Col, Drawer } from "antd";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import chatimage from "../../assets/images/cci-chat.png";
import BackgroundImage from "../../assets/chatdash/newbackground.svg";
import "./newweb.css";
// import "../../Component/LatestWebsite/newweb.css";
// import "../newweb.css"
// import "../../Component/LatestWebsite/"

import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import { useNavigate } from "react-router-dom";
import Section7 from "./Section7";

function NewWebsite() {
  const navigate = useNavigate();

  const section4Ref = useRef(null);

  const handleGetDemo =() =>{
    window.scrollTo({ top: 0, behavior: 'smooth' }); 
    navigate('/contact');
  }

  const handleFeaturesClick = (elementref) => {
    window.scrollTo({
      top: elementref.current.offsetTop,
      behavior: "smooth",
    });
  };

  const handleCombinedClick = () => {
    handleClickItem("features");
    handleFeaturesClick(section4Ref);
    setOpen(false);
  };

  const [open, setOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("home");

  const [placement, setPlacement] = useState("left");
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };
  const handleClickItem = (item) => {
    setActiveItem(item);
    setOpen(false);
  };
  return (
    <div>
      <header class="container">
        <Row>
          <Col span={24}>
            <div class="max-w-container flex gap-3 justify-between items-center mt-5">
              <a href="/">
                <img
                  src="./assets/images/logo.png"
                  alt="logo"
                  class="w-[180px] sm:w-[200px] md:w-[240px] logo-img"
                />
              </a>

              <div class="hidden md:inline-block">
                <button className="border border-clr  rounded-full px-4 py-2 mt-2">
                  <ul className="flex gap-5 list-none m-0 p-0 ">
                    <li>
                      <a
                        href="#home"
                        className="text-decoration-none text-color "
                      >
                        Home
                      </a>
                    </li>
                    <li>
                      {/* <p onClick={handleFeaturesClick} className="text-decoration-none txt-subwhite ">Features</p> */}
                      {/* <a  className="text-decoration-none txt-subwhite " onClick={window.scrollTo(document.getElementById('section4'))}>Features</a> */}
                      {/* <p onClick={handleFeaturesClick} className="text-decoration-none txt-subwhite">Features</p> */}

                      {/* <a  href="#" className="text-decoration-none txt-subwhite " onClick={handleScroll}>Features</a> */}

                      <a
                        onClick={() => handleFeaturesClick(section4Ref)}
                        className="text-decoration-none txt-subwhite"
                      >
                        Features
                      </a>
                    </li>
                  </ul>
                </button>
              </div>

              <div className="hidden md:inline-block mt-2">
                <Link
                  to="/contact"
                  className="flex items-center text-decoration-none"
                >
                  {/* <span className=" black-clr px-2 py-1 text-color">Contact us</span>

                            <Icon icon="iconamoon:arrow-right-2-duotone" style={{ color: "#d736d1" }} /> */}
                  <button className="black-clr ">
                    <span>Contact us</span>
                    <span className="gradient-icon">
                      <Icon icon="iconamoon:arrow-right-2-duotone" />
                    </span>
                  </button>
                </Link>
              </div>
              <div className="md:hidden">
                <div className="" onClick={showDrawer}>
                  <Icon
                    className="menu-cs "
                    icon="oi:menu"
                    width="1.2em"
                    height="1.2em"
                  />
                </div>
                <Drawer
                  className="left-drawer"
                  placement={placement}
                  closable={false}
                  open={open}
                  key={placement}
                >
                  <Icon
                    className="close-grey"
                    icon="mdi:close"
                    width="1.6em"
                    height="1.5em"
                    onClick={onClose}
                  />
                  <div className="mt-2">
                    <p
                      onClick={() => handleClickItem("home")}
                      className={
                        activeItem === "home"
                          ? "sidemenu-active"
                          : "sidemenu-inactive"
                      }
                    >
                      Home
                    </p>
                    <p
                      onClick={handleCombinedClick}
                      className={
                        activeItem === "features"
                          ? "sidemenu-active"
                          : "sidemenu-inactive"
                      }
                    >
                      Features
                    </p>
                  </div>
                </Drawer>
              </div>
            </div>
          </Col>
        </Row>
      </header>
      <section class="container">
        <Row>
          <Col span={24}>
            <div>
              <h1 class="heading-text xl:mt-24 capitalize heading-mob">
                <span>
                  Supercharge Customer Experience and Unlock Radical
                  Productivity with
                </span>
                <span class="text-gradient ms-2">Generative AI</span>
              </h1>
              <p class="margin-top subtitle-text text-sm md:text-base">
                AIVolveX<sup>TM</sup>-Generative AI-powered solution for
                seamless customer experience, <br></br> smarter decision-making,
                and enhanced productivity.
              </p>
            </div>
          </Col>
          <Col span={24}>
            <div className="d-flex justify-content-center p-3">
            {/* <Link to="/contact"> */}
              <button className="button-demo mx-1" onClick={handleGetDemo}>
                <span>Get a demo</span>
                <span className="">
                  <Icon
                    icon="iconamoon:arrow-right-2-duotone"
                    style={{ color: "white" }}
                  />
                </span>
              </button>
              {/* </Link> */}
              <button className="explore-button mx-1"  onClick={() => handleFeaturesClick(section4Ref)}>
                <span className="mt-1">
                  <Icon icon="mdi:thunder" />
                </span>
                <span className="ms-1">Explore All Features</span>
              </button>
            </div>
          </Col>
          <Col span={24}>
            <div className="relative">
              <div className=" topvalue  top-phone">
                <img
                  src={BackgroundImage}
                  className="w-full h-auto opacity-30"
                  alt="Chat Background"
                />
              </div>
              <div className="relative flex justify-center items-center">
                <img src={chatimage} className="w-[70%] h-auto" alt="Chat" />
              </div>
              <div className="topvalue1 top-phone1">
                <img
                  src={BackgroundImage}
                  className="opacity-30"
                  alt="Chat Background"
                />
              </div>
            </div>
          </Col>

          {/* <Col span={24} className="margin-company">
                        < h2 class=" company-text">
                            Companies we Work with
                        </h2>
                    </Col> */}

          {/* <Col span={24} className="margin-company1">

                        <Row justify="center" align="middle" >
                            <Col span={4}>
                                <div className="flex flex-col items-center svg-img">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 34 32" fill="none">
                                        <rect x="11" width="12" height="9" fill="#A6A6A6" />
                                        <rect y="23" width="34" height="9" fill="#A6A6A6" />
                                        <rect x="24" y="14" width="10" height="18" fill="#A6A6A6" />
                                        <rect y="14" width="10" height="18" fill="#A6A6A6" />
                                    </svg>
                                    <a href="#Unsplash" className="text-decoration-none subtitle-color ms-1">Unsplash</a>
                                </div>
                            </Col>
                            <Col span={4}>
                                <div className="flex flex-col items-center svg-img">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 34 32" fill="none">
                                        <rect x="11" width="12" height="9" fill="#A6A6A6" />
                                        <rect y="23" width="34" height="9" fill="#A6A6A6" />
                                        <rect x="24" y="14" width="10" height="18" fill="#A6A6A6" />
                                        <rect y="14" width="10" height="18" fill="#A6A6A6" />
                                    </svg>
                                    <a href="#Notion" className="text-decoration-none subtitle-color ms-1">Notion</a>
                                </div>
                            </Col>
                            <Col span={4}>
                                <div className="flex flex-col items-center svg-img">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                        <rect width="30" height="30" rx="3" fill="#A6A6A6" />
                                        <rect x="4" y="7" width="2" height="12" fill="white" />
                                        <rect x="9" y="4" width="2" height="18" fill="white" />
                                        <rect x="14" y="4" width="2" height="18" fill="white" />
                                        <rect x="19" y="4" width="2" height="18" fill="white" />
                                        <path d="M5 24C7.83333 26.2639 16 29.4333 26 24" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                        <rect x="24" y="7" width="2" height="12" fill="white" />
                                    </svg>
                                    <a href="#Intercom" className="text-decoration-none subtitle-color ms-1">INTERCOM</a>
                                </div>
                            </Col>
                            <Col span={4} className="p-1">
                                <div className="flex flex-col items-center svg-img">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="30" viewBox="0 0 23 30" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M17.0711 1.41723C18.9464 2.32466 20 3.5554 20 4.83871H10L0 4.83871C0 3.5554 1.05357 2.32466 2.92893 1.41722C4.8043 0.509791 7.34784 0 10 0C12.6522 0 15.1957 0.509791 17.0711 1.41723ZM2.92893 28.5828C1.05357 27.6753 0 26.4446 0 25.1613H10L20 25.1613C20 26.4446 18.9464 27.6753 17.0711 28.5828C15.1957 29.4902 12.6522 30 10 30C7.34783 30 4.8043 29.4902 2.92893 28.5828ZM23 17.4194V21.2903H17V17.4194H23ZM6 8.70968H0V12.5806H6V8.70968ZM11 8.70968H23V12.5806H11V8.70968ZM12 17.4194H0V21.2903H12V17.4194Z" fill="#A6A6A6" />
                                    </svg>
                                    <a href="#Descript" className="text-decoration-none subtitle-color ms-1">descript</a>
                                </div>
                            </Col>
                            <Col span={4}>
                                <div className="flex flex-col items-center svg-img">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                        <circle cx="15" cy="15" r="15" fill="#A6A6A6" />
                                    </svg>
                                    <a href="#Grammarly" className="text-decoration-none subtitle-color ms-1">grammarly</a>
                                </div>
                            </Col>
                        </Row>
                    </Col> */}

          <Section2 />
          <Section3 />

          <div ref={section4Ref}>
            <Section4 />
          </div>
          <Section5 />
        
          <Section6 />
  
        </Row>
      </section>
    </div>
  );
}
export default NewWebsite;
