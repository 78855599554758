import {
  Button,
  Form,
  Input,
  Col,
  Row,
  message,
  Checkbox,
  DatePicker,
} from "antd";
import { useState } from "react";
import moment from "moment";

const ContactForm = () => {
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);

  const { RangePicker } = DatePicker;

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  }

  const onFinish = (values) => {
    console.log(values);
    const app_date = getDateFormat(values.calender);
    // console.log(values.date, values.dateString);
    // setLoader(true);

    const formdata = new FormData();
    formdata.append("Name", values.fullname);
    formdata.append("Organization", values.organizationname);
    formdata.append("Position", values.jobtitle);
    formdata.append("Email", values.emailaddress);
    formdata.append("Phone_number", values.phonenumber);
    formdata.append("Message", values.message);
    formdata.append("App_date", app_date);
    console.log(app_date,"app_datevalue")

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    console.log(requestOptions);

    fetch(
      "https://script.google.com/macros/s/AKfycbxYb3xGyGeGQwGY-ut-bjMBtJzlXVc24MxMVr_jz3lmdrdRjgyUOnhNh1-6Q8aksohAvA/exec",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        const api_input = {
          values: values,
          app_date: app_date,
        };
        mailTrigger(api_input);
      })
      .catch((error) => console.error(error));
  };

  const getDateFormat = (dateString) => {
    // Parse the date string into a Date object
    const date = new Date(dateString);
    // Extract the day, month, and year
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, add 1
    const year = date.getFullYear();
    // Format the date as "DD/MM/YYYY"
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  const mailTrigger = (values) => {
    console.log(values,"valuesss")
    const name = values.values.fullname;
    const email = values.values.emailaddress;
    const job_title = values.values.jobtitle;
    const formmessage = values.values.message;
    const organization = values.values.organizationname;
    const phone_number = values.values.phonenumber;
    const app_date = values.app_date;

    console.log(app_date,"app_dat")
    console.log(values.app_date,"appdetails")
    const myHeaders = new Headers();
    myHeaders.append("x-api-key", "da2-vhjjnmmbinepdeo2nc36sroq6i");
    myHeaders.append("Content-Type", "application/json");

    const graphql = JSON.stringify({
      query:
        'query MyQuery {\r\n  website_email_trigger(email_id: "' +
        email +
        '", job_title: "' +
        job_title +
        '", members_name: "' +
        name +
        '", organization: "' +
        organization +
        '", phone_number: "' +
        phone_number +
        '", message: "' +
        formmessage +
        '",app_date: "' +
        app_date +
        '", event_type: "website-event")\r\n}\r\n',
      variables: {},
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: graphql,
      redirect: "follow",
    };

    fetch(
      "https://caqt4znsszh73kqzimjn22eexu.appsync-api.ap-south-1.amazonaws.com/graphql",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        form.resetFields();
        message.success(
          "Thank you for sharing your details! We'll be in touch soon"
        );
        setLoader(false);
      })
      .catch((error) => console.error(error));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Form
        name="basic"
        layout="vertical"
        labelCol={{
          span: 23,
        }}
        wrapperCol={{
          span: 23,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-center">
            <label className="form-title">Sign Up Now</label>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Full Name"
              name="fullname"
              rules={[
                {
                  required: true,
                  message: "Please input your Full Name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Email Address"
              name="emailaddress"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please input your valid Email Address!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Phone Number"
              name="phonenumber"
              rules={[
                {
                  required: true,
                  message: "Please input your Phone Number!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Job Title"
              name="jobtitle"
              rules={[
                {
                  required: true,
                  message: "Please input your Job Title!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label="Organization Name"
              name="organizationname"
              rules={[
                {
                  required: true,
                  message: "Please input your Organization Name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label="Message"
              name="message"
              rules={[
                {
                  required: true,
                  message: "Please input your Message!",
                },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label="Appointment Date"
              name="calender"
              rules={[
                {
                  required: true,
                  message: "Please select your Appointment Date!",
                },
              ]}
              // wrapperCol={{
              //   offset: 8,
              //   span: 16,
              // }}
            >
              <DatePicker
                disabledDate={disabledDate}
                maxTagCount="responsive"
                size="large"
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="plicy"
              // wrapperCol={{
              //   offset: 8,
              //   span: 16,
              // }}
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            "Please check the box to indicate that you agree to the policy."
                          )
                        ),
                },
              ]}
            >
              <Checkbox>
                I agree to the{" "}
                <a href="https://www.aivolvex.com/policy" target="_blank">
                  Privacy Policy
                </a>
                .{" "}
              </Checkbox>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              wrapperCol={{
                span: 24,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                className="btn-banner-form"
                loading={loader}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ContactForm;
