import React from "react";
import { Row, Col, Divider } from "antd";
import { Icon } from "@iconify/react";
import keyfeature from "../../assets/images/KeyFeature.png";
import Robot from "../../assets/images/robot.png";
import RoboEllipse from "../../assets/images/robo-ellipse.png";
import blueball from "../../assets/images/blueball.png";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
import RobolEllipseLeft from "../../assets/images/roboellipseleft.png";

const options = {
  series: [60],
  chart: {
    type: "radialBar",
    offsetY: -20,
    sparkline: {
      enabled: true,
    },
  },
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      track: {
        background: "#e7e7e7",
        strokeWidth: "97%",
        margin: 5,
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          color: "#999",
          opacity: 1,
          blur: 2,
        },
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          offsetY: -2,
          fontSize: "22px",
          color: "#fff",
          formatter: function (val) {
            return `${val}`;
          },
        },
      },
    },
  },
  grid: {
    padding: {
      top: -10,
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      shadeIntensity: 0.4,
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 50, 53, 91],
      colorStops: [
        {
          offset: -37.84,
          color: "#eb77e7",
          opacity: 1,
        },
        {
          offset: 7.81,
          color: "#d736d1",
          opacity: 1,
        },
        {
          offset: 113.98,
          color: "#9340e8",
          opacity: 1,
        },
      ],
    },
  },
  labels: ["Average Results"],
};

function Section4() {
  return (
    <>
      <div id="section4">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="margin-aivolvex">
              <div className="d-flex justify-content-center">
                <img src={keyfeature} alt="" />
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="margin-aivolvex">
              <h2 class="heading-text text-center utilize">
                Utilize the
                <span class="text-gradient ms-3">Potential</span>
                <span class="heading-text text-center ms-2">of</span>
                <br></br>
                <span class="text-gradient ms-2">Generative AI</span>
                <span class="heading-text text-center">
                  {" "}
                  within Your Enterprise.
                </span>
              </h2>
            </div>
          </Col>
        </Row>

        <Row className="d-flex justify-content-center container text-center">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="d-flex justify-content-center mt-4 ">
              <img src={Robot} class="robo-img" alt="" />
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="d-flex justify-content-center mt-4">
              <p className="robo-context">
                Our cutting-edge Generative AI technology empowers businesses to
                forge unbreakable bonds with their customers. Say goodbye to
                generic marketing and hello to personalized experiences that
                resonate.
              </p>
            </div>
            <div className="d-flex justify-content-start ">
              <Link className="flex items-center text-decoration-none">
                <button className="about-us">
                  <span className="">
                    <Icon
                      icon="iconamoon:arrow-right-2-duotone"
                      style={{ color: "white" }}
                    />
                  </span>
                  <span>Explore More</span>
                </button>
              </Link>
            </div>
          </Col>
        </Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="margin-aivolvex">
            <h2 class="heading-text text-center ">
              The big bang of
              <span class="text-gradient ms-2">enterprise</span>
              <br></br>
              <span class="text-gradient">productivity</span>
              <span class="heading-text text-center ms-2">is here </span>
            </h2>
          </div>
        </Col>

        <Row gutter={[16, 16]} className="margin-aivolvex">
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <div className="p-6 rounded-2xl column-1 h-full text-center ">
              <div className="d-flex justify-content-start"></div>

              <div className="d-flex justify-content-start">
                <p className="text-lg font-semibold text-gradient">
                  Corporate Intelligence Agents
                </p>
              </div>
              <div>
                <div className="text-gray-500 d-flex justify-content-start text-left text-justify">
                  These agents, integral for internal employees, excel in
                  retrieving critical information and accurately answering
                  complex queries using a Retrieval-Augmented Generation (RAG)
                  approach.
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center rag-margin">
                <p className="mb-0">RAG Technology</p>
                <h6 className="mb-0">
                  <Icon icon="fluent:arrow-up-right-16-regular" />
                </h6>
              </div>

              <div className="div-grey">
                <Divider />
              </div>
              {/* <div className="d-flex justify-content-start text-gray-500 mt-2"><p>AiVolveX GEN AI</p></div> */}
              <div className="d-flex justify-content-between align-items-center text-gray-500 mt-2">
                <p className="mb-0">AiVolveX GEN AI</p>
                <h6 className="mb-0">
                  <Icon icon="fluent:arrow-up-right-16-regular" />
                </h6>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="p-6 rounded-2xl column-1 h-full text-center">
                  <div className="d-flex justify-content-start"></div>

                  <div className="d-flex justify-content-start">
                    <p className="text-lg font-semibold text-gradient">
                      Customer Engagement Agents
                    </p>
                  </div>
                  <div>
                    <div className="text-gray-500 d-flex justify-content-start text-left text-justify">
                      Tailored to enhance customer interactions, this category
                      includes advanced Knowledge Fine-Tuned Chatbots and Call
                      Analytics tools.
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="p-6 rounded-2xl column-1 h-full text-center personal-assist">
                  <div className="d-flex justify-content-start"></div>

                  <div className="d-flex justify-content-start ">
                    <p className="text-lg font-semibold text-gradient">
                      Personal Assistant Agents
                    </p>
                  </div>
                  <div>
                    <div className="text-gray-500 d-flex justify-content-start text-left text-justify">
                      Custom-designed for individual employees, these agents
                      assist in personal tasks like document comparison,
                      summarization, and validation.{" "}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <div className="p-6 rounded-2xl column-1 h-full text-center enter-mob">
              <div className="d-flex justify-content-start"></div>

              <div className="d-flex justify-content-start">
                <p className="text-lg font-semibold text-gradient">
                  Enterprise Ready
                </p>
              </div>
              <div>
                <div className="text-gray-500 d-flex justify-content-start text-left text-justify">
                  AIvolveX™ by 1CloudHub is equipped with a comprehensive suite
                  of features, ensuring seamless integration and optimal
                  performance in enterprise environments{" "}
                </div>
              </div>
              <div id="chart" className="mt-4">
                <ReactApexChart
                  options={options}
                  series={options.series}
                  type="radialBar"
                  height={300}
                />
                <p className="text-gray-500">Optimal performance</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default Section4;
