import { Col, Row, Select } from "antd";
import { memo } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

const Footer = () => {
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  return (
    <>
      <footer className="container footer-cls footer-mobile">      
<Row>
  <Col span={24} >
  <div className=" d-flex justify-content-center ">
              <img
                src="./assets/images/logo.png"
                alt="logo"
                class="w-[180px] sm:w-[200px] md:w-[240px]"
              />
            </div>
            <div className="mt-4  d-flex justify-content-center">
              <h1 className="footer-title">Built by 1CloudHub</h1>
            </div>
            <div className="footer-icon-list mt-4 d-flex justify-content-center mob-align1">
            <div className="footer-icon">
  <a href="https://instagram.com/aivolvex" target="_blank" rel="noopener noreferrer">
    <Icon  className="social-media" icon="il:instagram" />
  </a>
</div>
              <div className="footer-icon">
              <a href="https://www.linkedin.com/company/aivolvex-ai/" target="_blank" rel="noopener noreferrer">
              <Icon className="social-media" icon="mdi:linkedin" />
              </a>
              </div>
             
              <div className="footer-icon">
              <a href="https://twitter.com/AIVolveX" target="_blank" rel="noopener noreferrer">
              <Icon className="social-media" icon="jam:twitter-square" />
              </a>
              </div>
              <div className="footer-icon">
              <a href="https://medium.com/@aivolvex" target="_blank" rel="noopener noreferrer">
              <Icon className="social-media" icon="fa6-brands:medium" />
              </a>
              </div>
              <div className="footer-icon">
              <a href="https://in.pinterest.com/aivolvex/" target="_blank" rel="noopener noreferrer">
              <Icon  className="social-media" icon="akar-icons:pinterest-fill" />
              </a>
              </div>
              <div className="footer-icon">
              <a href="https://www.youtube.com/@AIVolveX" target="_blank" rel="noopener noreferrer">
              <Icon  className="social-media" icon="entypo-social:youtube" />
              </a>
              </div>
            </div>
  </Col>
  <Col span={24} className="mt-4">

  <Row className="align-items-center text-center">
  <Col span={24}>
    <div className="d-flex justify-content-center align-items-center">
      <h1 className="copy-right-footer">
        ©{new Date().getFullYear()} AIVolveX. All rights reserved.
      </h1>
    </div>
  </Col>
 
  <Col span={24} className="d-flex justify-content-end">
    <div className="d-flex justify-content-end privacy-pol">
  <div>
    <Link to="/policy">
      <label className="footer-font">Privacy Policy</label>
    </Link>
    </div>
    <div>
    <div className="footer-icon-list ms-2">
                  <div className="mt-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="20"
                      viewBox="0 0 19 20"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_3039_4886)">
                        <path
                          d="M12.7284 6.26592C12.1933 2.90598 10.9315 0.546875 9.46297 0.546875C7.99447 0.546875 6.73259 2.90598 6.19758 6.26592H12.7284ZM5.92085 9.87794C5.92085 10.7132 5.96513 11.5146 6.04261 12.286H12.8796C12.9571 11.5146 13.0014 10.7132 13.0014 9.87794C13.0014 9.04266 12.9571 8.24124 12.8796 7.46993H6.04261C5.96513 8.24124 5.92085 9.04266 5.92085 9.87794ZM17.9013 6.26592C16.8461 3.71116 14.7097 1.73583 12.0716 0.938178C12.9719 2.20991 13.5917 4.12504 13.9164 6.26592H17.9013ZM6.85066 0.938178C4.21621 1.73583 2.07618 3.71116 1.02461 6.26592H5.00949C5.3305 4.12504 5.95037 2.20991 6.85066 0.938178ZM18.2961 7.46993H14.064C14.1415 8.26006 14.1858 9.069 14.1858 9.87794C14.1858 10.6869 14.1415 11.4958 14.064 12.286H18.2924C18.4954 11.5146 18.6097 10.7132 18.6097 9.87794C18.6097 9.04266 18.4954 8.24124 18.2961 7.46993ZM4.74015 9.87794C4.74015 9.069 4.78442 8.26006 4.86191 7.46993H0.629815C0.430571 8.24124 0.3125 9.04266 0.3125 9.87794C0.3125 10.7132 0.430571 11.5146 0.629815 12.286H4.85822C4.78442 11.4958 4.74015 10.6869 4.74015 9.87794ZM6.19758 13.49C6.73259 16.8499 7.99447 19.209 9.46297 19.209C10.9315 19.209 12.1933 16.8499 12.7284 13.49H6.19758ZM12.0753 18.8177C14.7097 18.0201 16.8498 16.0447 17.905 13.49H13.9201C13.5954 15.6308 12.9756 17.546 12.0753 18.8177ZM1.02461 13.49C2.07987 16.0447 4.21621 18.0201 6.85435 18.8177C5.95406 17.546 5.33419 15.6308 5.00949 13.49H1.02461Z"
                          fill="#656565"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3039_4886">
                          <rect
                            width="18.3009"
                            height="19.2641"
                            fill="white"
                            transform="translate(0.3125 0.246094)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <Select
                    showSearch
                    className="footer-language"
                    placeholder="Select a person"
                    optionFilterProp="children"
                    filterOption={filterOption}
                    defaultValue="en"
                    options={[
                      {
                        value: "en",
                        label: "en",
                      },
                    ]}
                  />
                </div>
    </div>
    </div>
  </Col>
</Row>
  </Col>
</Row>


      
      </footer>
    </>
  );
};

export default memo(Footer);