import React from "react";
import ContactEvent from "./ContactEvent";

const Event = () => {

  return (
 <div><ContactEvent/></div>
  );
};

export default Event;
