import "./App.css";
import React, { useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import Website from "./Component/Website";
import Brochure from "./Component/Brochure";
import Contact from "./Component/Contact";
import Policy from "./Component/Policy";
import NewWebsite from "./Component/LatestWebsite/NewWeb";
import Section4 from "./Component/LatestWebsite/Section4";
import Event from "./Component/LatestWebsite/Event";
function App() {
  // document.documentElement.style.setProperty("--main-color", "#fff");AaZswxzzx
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<NewWebsite />} />
          <Route path="/brochure" element={<Brochure />} />
          {/* <Route path="/staging" element={<Website />} /> */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/section4" element={<Section4 />} />
          <Route path="/signup" element={<Event />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
