import React from "react";
import {Row,Col,Card,Collapse, Button} from 'antd';
import { Icon } from "@iconify/react";

import Ellipse1 from "../../assets/images/ellipse1.png";
import Ellipse2 from "../../assets/images/ellipse2.png";
import Ellipse3 from "../../assets/images/ellipse3.png";
import Ellipse4 from "../../assets/images/ellipse4.png";
import Ellipse5 from "../../assets/images/ellipse5.png";
import Ellipse6 from "../../assets/images/ellipse6.png";
import RobolEllipseLeft from "../../assets/images/roboellipseleft.png";



function Section5(){
    const { Panel } = Collapse;


    const items = [
        {
          key: "1",
          label: "01 What security measures does AIVolveX™ have for protecting enterprise data?",
          children: (
            <p>
              AIVolveX™ ensures data security through encryption protocols, access controls, security audits, and strict privacy policies, prioritizing the privacy of your enterprise data.
            </p>
          ),
        },
        {
          key: "2",
          label: "02 How does AIVolveX™ boost enterprise learning with advanced AI?",
          children: (
            <p>
              AIVolveX™ enhances learning by providing AI-driven insights, personalized content, and interactive, adaptive learning experiences for enterprise teams.
            </p>
          ),
        },
        {
          key: "3",
          label: "03 How can I create AX Agents tailored to my enterprise's essence and needs?",
          children: (
            <p>
              Create unique AX Agents by specifying your enterprise's goals and needs, allowing AIVolveX™ to tailor agents' expertise accordingly.
            </p>
          ),
        },
        {
          key: "4",
          label: "04 How does AiVolveX's conversational AI boost enterprise efficiency?",
          children: (
            <p>
              AIVolveX's conversational AI simplifies complex tasks, offers quick information retrieval, and facilitates decision-making, boosting overall enterprise efficiency.
            </p>
          ),
        },
        {
          key: "5",
          label: "05 What criteria should I consider to see if AIVolveX™ fits my business?",
          children: (
            <p>
              Consider AIVolveX™'s compatibility with existing systems, scalability, ease of integration, and potential to meet specific enterprise knowledge objectives.
            </p>
          ),
        },
      ];
      
  
    return(
        <>
        <Row>
          

<Col xs={24} sm={24} md={24} lg={24} xl={24}>
  <div className="margin-aivolvex">
    <h2 className="heading-text text-center">
      Frequently Asked Questions 
      <span className="text-gradient ms-2">(FAQs)</span>
    </h2>
  </div>
</Col >
{/* <div className="p-6"> */}

<Col xs={24} sm={24} md={24} lg={24} xl={24} className="collapse-margin">
    <div className="custom-collapse-container">
      <Collapse
        className="custom1-collapse"
        expandIcon={({ isActive }) => <Icon icon={isActive ? "material-symbols:remove" : "material-symbols:add"} width="2em" height="2em"/>}
        expandIconPosition="right"
      >
        {items.map(item => (
          <Panel header={item.label} key={item.key} className="custom-panel">
            {item.children}
          </Panel>
        ))}
      </Collapse>
    </div>
 
  </Col>
   

</Row>

<Col xs={24} sm={24} md={24} lg={24} xl={24}>
{/* <div className="absolute">
 <img
          src={RobolEllipseLeft}
        //   className="w-full h-auto opacity-30"
        class="robo-left"
          alt="Chat Background"
        />
      </div> */}

 <div className="margin-aivolvex">


    <h2 className="heading-text text-center">
    What Our Client Says<span className="text-gradient ms-2">About Us</span>
    </h2>
  </div>
 

 <Row className="text-center margin-aivolvex" gutter={[16,16]} >

    <Col  xs={24} sm={8} md={8} lg={8} xl={8}>

    <div className="p-6 rounded-2xl mark-column h-full text-center">
          
         
            <div className="d-flex justify-content-start">
            <img src={Ellipse1}
            
            alt="" />

            </div> 
            <div className="">
          <h5>Mark Thompson</h5>
        <p>CEO of Digital Dynamics</p> 
          </div>
            <div className="d-flex justify-content-end">
         
    <div className="text-gray-500 d-flex justify-content-start text-left text-justify">
    AIvolveX has been instrumental in helping us connect with our customers on a deeper level. Their Generative AI technology has allowed us to deliver personalized experiences that resonate with each individual, driving engagement and loyalty.    </div>
</div>

            </div>


    </Col>
    <Col  xs={24} sm={8} md={8} lg={8} xl={8}>
    <div className="p-6 rounded-2xl mark-column h-full text-center">
            <div className="d-flex justify-content-start">
         

            </div>
         
            <div className="d-flex justify-content-start">
            <img src={Ellipse2}
            
            alt="" />

            </div> 
            <div className="">
          <h5>Rachel Adams</h5>
        <p>CEO of VU Technologies</p> 
          </div>
            <div className="d-flex justify-content-end">
         
    <div className="text-gray-500 d-flex justify-content-start text-left text-justify">
    The team at AIvolveX is exceptional. Their dedication to our success and their innovative solutions have surpassed our expectations. Thanks to AIvolveX, we've seen a significant increase in customer satisfaction and retention.   
    we've witnessed a significant improvement in customer satisfaction and retention rates.  </div>
</div>

            </div>
    </Col>
    <Col  xs={24} sm={8} md={8} lg={8} xl={8}>
    <div className="p-6 rounded-2xl mark-column h-full text-center">
            <div className="d-flex justify-content-start">
         

            </div>
         
            <div className="d-flex justify-content-start">
            <img src={Ellipse3}
            
            alt="" />

            </div> 
            <div className="">
          <h5>Alex Martinez</h5>
        <p>Founder of Online Ventures Inc.</p> 
          </div>
            <div className="d-flex justify-content-end">
         
    <div className="text-gray-500 d-flex justify-content-start text-left text-justify">
    Choosing AIvolveX was truly a game-changer for our business. When we made the decision to integrate AI-powered customer engagement into our operations, we knew it was a significant step forward. What we didn't anticipate was just how seamless the process would be with AIvolveX.     
    </div>
</div>

            </div>
    </Col>
 







  {/* <Row className="text-center" gutter={[16,16]}> */}

<Col  xs={24} sm={8} md={8} lg={8} xl={8}>

<div className="p-6 rounded-2xl mark-column h-full text-center">
        <div className="d-flex justify-content-start">
     

        </div>
     
        <div className="d-flex justify-content-start">
        <img src={Ellipse4}
        
        alt="" />

        </div> 
        <div className="">
      <h5>Zaire Dokidis</h5>
    <p>CEO of Global Retail Solutions</p> 
      </div>
        <div className="d-flex justify-content-end">
     
<div className="text-gray-500 d-flex justify-content-start text-left text-justify">
The expertise and dedication of the team at AIvolveX have truly impressed us. From the start, they demonstrated a deep commitment to understanding our business needs. This level of dedication ensured that the solutions they provided were not only innovative but also perfectly tailored to our requirements.

 </div>
</div>

        </div>


</Col>
<Col  xs={24} sm={8} md={8} lg={8} xl={8}>
    <div className="p-6 rounded-2xl mark-column h-full text-center">
            <div className="d-flex justify-content-start">
         

            </div>
         
            <div className="d-flex justify-content-start">
            <img src={Ellipse5}
            
            alt="" />

            </div> 
            <div className="">
          <h5>Leo Martinez</h5>
        <p>Marketing Head Nexus Technology</p> 
          </div>
            <div className="d-flex justify-content-end">
         
    <div className="text-gray-500 d-flex justify-content-start text-left text-justify">
    Their deep understanding of AI technology and its potential in customer engagement allowed them to deliver cutting-edge solutions that surpassed industry standards.
 Since implementing AIvolveX's solutions, we've witnessed a significant improvement in customer satisfaction and retention rates.     </div>
</div>

            </div>
    </Col>
<Col  xs={24} sm={8} md={8} lg={8} xl={8}>
<div className="p-6 rounded-2xl mark-column h-full text-center">
        <div className="d-flex justify-content-start">
     

        </div>
     
        <div className="d-flex justify-content-start">
        <img src={Ellipse6}
        
        alt="" />

        </div> 
        <div className="">
      <h5>Amanda Bakels</h5>
    <p>Director of Spark Inc</p> 
      </div>
        <div className="d-flex justify-content-end">
     
<div className="text-gray-500 d-flex justify-content-start text-left text-justify">
I can't imagine running our e-commerce business without Aivolvex. Its predictive analytics capabilities have allowed us to forecast demand more accurately, optimize inventory levels, and ultimately increase our profitability. Aivolvex is a must-have tool for any online retailer
</div>
</div>

        </div>
</Col>
{/* </Row> */}

</Row>





{/* 
<div className="d-flex justify-content-center mt-4">
                            <button className="question-us">
                            <span className="">
                            <Icon icon="solar:alt-arrow-right-linear" />
                                </span>
                                <span>Read more</span>
                               
                            </button>
                            </div> */}


</Col>




{/* <Col xs={24} sm={24} md={24} lg={24} xl={24}
className="margin-aivolvex"
>

<div className="d-flex flex-column">
  <h2 className="heading-text text-start">
    Explore AIvolveX's
    <span className="text-gradient ms-2">Blog Posts</span>
  </h2>
  <div className="d-flex justify-content-end blog-view">
    <button className="question-us">
      <span>
        <Icon icon="fluent:arrow-up-right-16-regular" />
      </span>
      <span>View All Blogs</span>
    </button>
  </div>
</div>

</Col> */}

  
</>
    
    )
}
export default Section5;
