import React from "react";
import { Row, Col, Card, Collapse, Button, Divider } from "antd";
import { Icon } from "@iconify/react";
import ReactApexChart from "react-apexcharts";
import businesscase from "../../assets/images/business-case.png";
import fullcolor from "../../assets/images/fullcolor.svg";
import fullframe from "../../assets/images/fullFrame.svg";
import glowr from "../../assets/images/glow-r.png";
import glowl from "../../assets/images/glow-l.png";
import { Link, useNavigate } from "react-router-dom";

const BigBan = (props) => {
  const { Panel } = Collapse;

  const navigate = useNavigate();

  const handleNavigation = () => {
    window.scrollTo(0, 0);
    navigate("/contact");
  };

  const options = {
    series: [60],
    chart: {
      type: "radialBar",
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: "97%",
          margin: 5,
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: "#999",
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "22px",
            color: "#fff",
            formatter: function (val) {
              return `${val}`;
            },
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
        colorStops: [
          {
            offset: -37.84,
            color: "#eb77e7",
            opacity: 1,
          },
          {
            offset: 7.81,
            color: "#d736d1",
            opacity: 1,
          },
          {
            offset: 113.98,
            color: "#9340e8",
            opacity: 1,
          },
        ],
      },
    },
    labels: ["Average Results"],
  };

  const items = [
    {
      key: "1",
      label:
        "01 What security measures does AIVolveX™ have for protecting enterprise data?",
      children: (
        <p>
          AIVolveX™ ensures data security through encryption protocols, access
          controls, security audits, and strict privacy policies, prioritizing
          the privacy of your enterprise data.
        </p>
      ),
    },
    {
      key: "2",
      label:
        "02 How does AIVolveX™ boost enterprise learning with advanced AI?",
      children: (
        <p>
          AIVolveX™ enhances learning by providing AI-driven insights,
          personalized content, and interactive, adaptive learning experiences
          for enterprise teams.
        </p>
      ),
    },
    {
      key: "3",
      label:
        "03 How can I create AX Agents tailored to my enterprise's essence and needs?",
      children: (
        <p>
          Create unique AX Agents by specifying your enterprise's goals and
          needs, allowing AIVolveX™ to tailor agents' expertise accordingly.
        </p>
      ),
    },
    {
      key: "4",
      label:
        "04 How does AiVolveX's conversational AI boost enterprise efficiency?",
      children: (
        <p>
          AIVolveX's conversational AI simplifies complex tasks, offers quick
          information retrieval, and facilitates decision-making, boosting
          overall enterprise efficiency.
        </p>
      ),
    },
    {
      key: "5",
      label:
        "05 What criteria should I consider to see if AIVolveX™ fits my business?",
      children: (
        <p>
          Consider AIVolveX™'s compatibility with existing systems, scalability,
          ease of integration, and potential to meet specific enterprise
          knowledge objectives.
        </p>
      ),
    },
  ];
  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="margin-aivolvex">
            <h2 class="heading-text text-center ">
              The big bang of
              <span class="text-gradient ms-2">enterprise</span>
              <br></br>
              <span class="text-gradient">productivity</span>
              <span class="heading-text text-center ms-2">is here </span>
            </h2>
          </div>
        </Col>

        <Row gutter={[16, 16]} className="margin-aivolvex">
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <div className="p-6 rounded-2xl column-1 h-full text-center ">
              <div className="d-flex justify-content-start"></div>

              <div className="d-flex justify-content-start">
                <p className="text-lg font-semibold text-gradient">
                  Corporate Intelligence Agents
                </p>
              </div>
              <div>
                <div className="text-gray-500 d-flex justify-content-start text-left text-justify">
                  These agents, integral for internal employees, excel in
                  retrieving critical information and accurately answering
                  complex queries using a Retrieval-Augmented Generation (RAG)
                  approach.
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center rag-margin">
                <p className="mb-0">RAG Technology</p>
                <h6 className="mb-0">
                  <Icon icon="fluent:arrow-up-right-16-regular" />
                </h6>
              </div>

              <div className="div-grey">
                <Divider />
              </div>
              {/* <div className="d-flex justify-content-start text-gray-500 mt-2"><p>AiVolveX GEN AI</p></div> */}
              <div className="d-flex justify-content-between align-items-center text-gray-500 mt-2">
                <p className="mb-0">AiVolveX GEN AI</p>
                <h6 className="mb-0">
                  <Icon icon="fluent:arrow-up-right-16-regular" />
                </h6>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="p-6 rounded-2xl column-1 h-full text-center">
                  <div className="d-flex justify-content-start"></div>

                  <div className="d-flex justify-content-start">
                    <p className="text-lg font-semibold text-gradient">
                      Customer Engagement Agents
                    </p>
                  </div>
                  <div>
                    <div className="text-gray-500 d-flex justify-content-start text-left text-justify">
                      Tailored to enhance customer interactions, this category
                      includes advanced Knowledge Fine-Tuned Chatbots and Call
                      Analytics tools.
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="p-6 rounded-2xl column-1 h-full text-center personal-assist">
                  <div className="d-flex justify-content-start"></div>

                  <div className="d-flex justify-content-start ">
                    <p className="text-lg font-semibold text-gradient">
                      Personal Assistant Agents
                    </p>
                  </div>
                  <div>
                    <div className="text-gray-500 d-flex justify-content-start text-left text-justify">
                      Custom-designed for individual employees, these agents
                      assist in personal tasks like document comparison,
                      summarization, and validation.{" "}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <div className="p-6 rounded-2xl column-1 h-full text-center enter-mob">
              <div className="d-flex justify-content-start"></div>

              <div className="d-flex justify-content-start">
                <p className="text-lg font-semibold text-gradient">
                  Enterprise Ready
                </p>
              </div>
              <div>
                <div className="text-gray-500 d-flex justify-content-start text-left text-justify">
                  AIvolveX™ by 1CloudHub is equipped with a comprehensive suite
                  of features, ensuring seamless integration and optimal
                  performance in enterprise environments{" "}
                </div>
              </div>
              <div id="chart" className="mt-4">
                <ReactApexChart
                  options={options}
                  series={options.series}
                  type="radialBar"
                  height={300}
                />
                <p className="text-gray-500">Optimal performance</p>
              </div>
            </div>
          </Col>
        </Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="margin-aivolvex">
            <h2 className="heading-text text-center">
              Frequently Asked Questions
              <span className="text-gradient ms-2">(FAQs)</span>
            </h2>
          </div>
        </Col>
        {/* <div className="p-6"> */}

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="collapse-margin"
        >
          <div className="custom-collapse-container">
            <Collapse
              className="custom1-collapse"
              expandIcon={({ isActive }) => (
                <Icon
                  icon={
                    isActive
                      ? "material-symbols:remove"
                      : "material-symbols:add"
                  }
                  width="2em"
                  height="2em"
                />
              )}
              expandIconPosition="right"
            >
              {items.map((item) => (
                <Panel
                  header={item.label}
                  key={item.key}
                  className="custom-panel"
                >
                  {item.children}
                </Panel>
              ))}
            </Collapse>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-4">
          <section className="mt-10 relative">
            <img src={fullframe} alt="img" className="bottom-box-fullframe" />
            <img src={fullcolor} alt="img" className="bottom-box-fullcolor" />
            <img src={glowr} alt="img" className="bottom-box-glowr" />
            <img src={glowl} alt="img" className="bottom-box-glowl" />
            <div className="container ">
              <div className="bottom-box text-center">
                <h1 className="bottom-box-title">
                  Stop guessing. Make data-driven decisions & move smarter,
                  faster with AIVolveX
                </h1>
                <p className="bottom-box-content">
                  Get a demo and discover how you can use AIVolveX agents to add
                  a Gen AI superpower to your business.
                </p>
                <button
                  class="bottom-box-btn"
                  onClick={() => props.scrollToSection(props.section1Ref)}
                >
                  Schedule a Demo today
                </button>
              </div>
            </div>
          </section>
        </Col>
      </Row>
    </>
  );
};

export default BigBan;
