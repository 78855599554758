import { memo } from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import bannertagimage from "../assets/images/contact/bannerPattern.svg";

const Policy = () => {
  return (
    <>
      <div className="">
        <section className="relative container">
          <img
            src={bannertagimage}
            alt="tag"
            className="absolute bannertagimage"
          />
          <Row className="my-5">
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className="header-left"
            >
              <Link to="/">
                <img
                  src={logo}
                  alt="logo"
                  className="w-[180px] sm:w-[200px] md:w-[240px]"
                />
              </Link>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className="header-right"
            >
              {/* <a
                href="https://forms.gle/zst8asfP9Px2YEck8"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-primary uppercase banner-button"
                style={{ display: "flex" }}
              >
                Experience Excellence
                <AiOutlineArrowRight style={{ marginTop: "4px" }} />
              </a> */}
            </Col>
          </Row>
        </section>
        <section className=" container">
          <Row className="my-5">
            <Col span={24} className="text-center">
              <label className="banner-main-content2">
                Privacy Policy
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 486 21"
                  fill="none"
                >
                  <path
                    d="M2.16916 18.5863C160.752 -2.01068 386.273 0.237108 484.177 8.11581"
                    stroke="url(#paint0_linear_2773_5352)"
                    stroke-width="2.9578"
                    stroke-linecap="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2773_5352"
                      x1="-251.215"
                      y1="7.91491"
                      x2="-216.23"
                      y2="171.902"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#EB77E7" />
                      <stop offset="0.46875" stop-color="#D736D1" />
                      <stop offset="1" stop-color="#9340E8" />
                    </linearGradient>
                  </defs>
                </svg>
              </label>
            </Col>
            <Col span={24} className="text-center">
              <p className="banner-p-policy mt-4">
                Welcome to AIVolveX, a GenAI Platform built by 1CloudHub. At
                AIVolveX, we are committed to protecting your privacy. This
                Privacy Policy explains how we collect, use, disclose, and
                safeguard your information when you use our platform. This
                Privacy Policy (the “Policy”) applies to the collection, use and
                disclosure of an individual customer’s Personal Data
                (hereinafter defined) arising from goods and/or services offered
                by 1CloudHub Pte. Ltd.
              </p>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <label className="banner-contact">
                1. What information do we collect?{" "}
              </label>
            </Col>
            <Col span={24} className="text-center">
              <p className="banner-p-policy-left mt-4">
                1.1. We collect information from you when you register on our
                site, place an order or subscribe to our newsletter.
              </p>
              <p className="banner-p-policy-left mt-4">
                1.2. When ordering or registering on our site, as appropriate,
                you may be asked to enter your: name, e-mail address, mailing
                address or phone number. You may, however, visit our site
                anonymously.
              </p>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <label className="banner-contact">
                2. What do we use your information for?
              </label>
            </Col>
            <Col span={24} className="text-center">
              <p className="banner-p-policy-left mt-4">
                2.1 Any of the information we collect from you may be used in
                one of the following ways:
              </p>
              <p className="banner-p-policy-left mt-4">
                2.1.1. To personalize your experience (your information helps us
                to better respond to your individual needs)
              </p>
              <p className="banner-p-policy-left mt-4">
                2.1.2. To improve our website (we continually strive to improve
                our website offerings based on the information and feedback we
                receive from you){" "}
              </p>
              <p className="banner-p-policy-left mt-4">
                2.1.3. To improve customer service (your information helps us to
                more effectively respond to your customer service requests and
                support needs){" "}
              </p>
              <p className="banner-p-policy-left mt-4">
                {" "}
                2.1.4. To process transactions (Your information, whether public
                or private, will not be sold, exchanged, transferred, or given
                to any other company for any reason whatsoever, without your
                consent, other than for the express purpose of delivering the
                purchased product or service requested).{" "}
              </p>
              <p className="banner-p-policy-left mt-4">
                {" "}
                2.1.5. To send periodic emails (The email address you provide
                for order processing, will only be used to send you information
                and updates pertaining to your order).{" "}
              </p>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <label className="banner-contact">
                3. How do we protect your information?
              </label>
            </Col>
            <Col span={24} className="text-center">
              <p className="banner-p-policy-left mt-4">
                3.1. We implement a variety of security measures to maintain the
                safety of your personal information when you place an order or
                enter, submit, or access your personal information.
              </p>
              <p className="banner-p-policy-left mt-4">
                3.2. We offer the use of a secure server. All supplied
                sensitive/credit information is transmitted via Secure Socket
                Layer (SSL) technology and then encrypted into our Payment
                gateway providers database only to be accessible by those
                authorized with special access rights to such systems, and are
                required to keep the information confidential.
              </p>
              <p className="banner-p-policy-left mt-4">
                3.3. After a transaction, your private information (credit
                cards, social security numbers, financials, etc.) will not be
                stored on our servers.
              </p>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <label className="banner-contact">4. Do we use cookies?</label>
            </Col>
            <Col span={24} className="text-center">
              <p className="banner-p-policy-left mt-4">
                4.1. Yes (Cookies are small files that a site or its service
                provider transfers to your computer’s hard drive through your
                Web browser (if you allow) that enables the sites or service
                providers systems to recognize your browser and capture and
                remember certain information.
              </p>
              <p className="banner-p-policy-left mt-4">
                4.2. We use cookies to help us remember and process your
                interests quicker
              </p>
              <p className="banner-p-policy-left mt-4">
                4.3. If you prefer, you can choose to have your computer warn
                you each time a cookie is being sent, or you can choose to turn
                off all cookies via your browser settings. Like most websites,
                if you turn your cookies off, some of our services may not
                function properly. However, you can still contact us using the
                information provided.
              </p>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <label className="banner-contact">
                5. Do we disclose any information to outside parties?
              </label>
            </Col>
            <Col span={24} className="text-center">
              <p className="banner-p-policy-left mt-4">
                5.1. We do not sell, trade, or otherwise transfer to outside
                parties your personally identifiable information. This does not
                include trusted third parties who assist us in operating our
                website, conducting our business, or servicing you, so long as
                those parties agree to keep this information confidential. We
                may also release your information when we believe release is
                appropriate to comply with the law, enforce our site policies,
                or protect ours or others rights, property, or safety. However,
                non-personally identifiable visitor information may be provided
                to other parties for marketing, advertising, or other uses.
              </p>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <label className="banner-contact">6. Third party links</label>
            </Col>
            <Col span={24} className="text-center">
              <p className="banner-p-policy-left mt-4">
                6.1. Occasionally, at our discretion, we may include or offer
                third party products or services on our website. These third
                party sites have separate and independent privacy policies. We
                therefore have no responsibility or liability for the content
                and activities of these linked sites. Nonetheless, we seek to
                protect the integrity of our site and welcome any feedback about
                these sites.
              </p>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <label className="banner-contact">
                7. Personal Data Protection Act (Singapore)
              </label>
            </Col>
            <Col span={24} className="text-center">
              <p className="banner-p-policy-left mt-4">
                7.1. By using this site, you agree to allow us to collect or use
                your information in an appropriate manner in accordance to the
                Personal Data Protection Act of Singapore.
              </p>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <label className="banner-contact">
                8. Online Privacy Policy Only
              </label>
            </Col>
            <Col span={24} className="text-center">
              <p className="banner-p-policy-left mt-4">
                8.1. This online privacy policy applies only to information
                collected through our website and not to information collected
                offline.
              </p>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <label className="banner-contact">9. Terms and Conditions</label>
            </Col>
            <Col span={24} className="text-center">
              <p className="banner-p-policy-left mt-4">
                9.1. Please also visit our Terms and Conditions section
                establishing the use, disclaimers, and limitations of liability
                governing the use of our website.
              </p>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <label className="banner-contact">10. Your Consent</label>
            </Col>
            <Col span={24} className="text-center">
              <p className="banner-p-policy-left mt-4">
                10.1. By using our site, you consent to our website’s Privacy
                Policy.
              </p>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <label className="banner-contact">
                11. Changes to our Privacy Policy
              </label>
            </Col>
            <Col span={24} className="text-center">
              <p className="banner-p-policy-left mt-4">
                11.1. If we decide to change our privacy policy, we will post
                those changes on this page, and/or update the Privacy Policy
                modification date below.
              </p>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <label className="banner-contact">12. Contact Us</label>
            </Col>
            <Col span={24} className="text-center">
              <p className="banner-p-policy-left mt-4">
                12.1. If there are any questions regarding this Privacy Policy
                you may contact us at contactus@1cloudhub.com
              </p>
            </Col>
          </Row>
        </section>
      </div>
      <footer class="footer mt-8 md:mt-40 py-24 relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1916 3"
          fill="none"
        >
          <path
            d="M-3 1.2168L1916 1.21696"
            stroke="url(#paint0_linear_2773_5388)"
            stroke-width="2"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2773_5388"
              x1="542.022"
              y1="1.2926"
              x2="542.133"
              y2="10.8592"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EB77E7" />
              <stop offset="0.463542" stop-color="#D736D1" />
              <stop offset="1" stop-color="#9340E8" />
            </linearGradient>
          </defs>
        </svg>
        <div class="max-w-container grid place-content-center h-full mt-4">
          <div class="text-center">
            <img
              src="./assets/images/logo.png"
              alt="logo"
              class="w-[180px] sm:w-[200px] md:w-[240px] mx-auto"
            />
            <p class="text-sm md:text-base mt-4 text-gray-500">
              AIVolveX.Ai™ Ltd 2011-{new Date().getFullYear()} © - All rights
              reserved.
            </p>
          </div>
        </div>
        <div className="footer-policy">
          <Link to="/policy">
            <label className="footer-font">Privacy Policy</label>
          </Link>
        </div>
      </footer>
    </>
  );
};

export default memo(Policy);
