import React from "react";
import { Col, Row } from "antd";
import { Icon } from "@iconify/react";
import Bulb from "../../assets/images/bulb.png";
import Innovation from "../../assets/images/Innovation.png";
import Vector from "../../assets/images/Vector.png";
// import BlueHolo from "../../assets/images/blueholo.png";
import RoboEllipse from "../../assets/images/robo-ellipse.png";
// import BlueBall from "../../assets/images/blue";
// import blueholoimg from "../../assets/chatdash/blueholoimg.svg";

function Section3() {
  return (
    <>
      <Col span={24}>
        <div className="margin-aivolvex">
          {/* <div className="d-flex justify-content-start">
            <img src={BlueHolo}
            class="blueholo"
            alt="" />
           


          
 </div> */}

          <div className="absolute">
            <img
              src={RoboEllipse}
              //   className="w-full h-auto opacity-30"
              class="robo-background"
              alt="Chat Background"
            />
          </div>

          <h2 class="heading-text text-center">
            Why Choose
            <span class="text-gradient ms-3">AIVolveX?</span>
          </h2>
        </div>
      </Col>
      <Col span={24}>
        <div className="margin-aivolvex">
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="mt-2">
              <div className="p-6 rounded-2xl column-1 h-full text-center">
                <div className="d-flex justify-content-start">
                  <img src={Bulb} alt="" />
                </div>
                <div className="d-flex justify-content-end">
                  <h2 className="num-font">01</h2>
                </div>
                <div className="d-flex justify-content-start">
                  <p className="text-lg font-semibold text-gradient">
                    Innovative Solutions
                  </p>
                </div>
                <div>
                  <p className="text-gray-500 d-flex justify-content-start text-left text-justify">
                    Experience the forefront of AI innovation with AIvolveX. Our
                    cutting-edge technology is designed to revolutionize the way
                    you engage with your customers, ensuring you stay ahead of
                    the competition.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="mt-2">
              <div className="p-6 rounded-2xl column-1 h-full text-center">
                <div className="d-flex justify-content-start">
                  <img src={Innovation} alt="" />
                </div>
                <div className="d-flex justify-content-end">
                  <h2 className="num-font">02</h2>
                </div>
                <div className="d-flex justify-content-start">
                  <p className="text-lg font-semibold text-gradient">
                    Tailored Approach
                  </p>
                </div>
                <div className="d-flex justify-content-start">
                  <p className=" text-gray-500 text-justify">
                    We understand that every business is unique. That's why we
                    take a personalized approach to every client, working
                    closely with you to understand your specific needs and goals
                    and tailor our solutions accordingly.
                  </p>
                </div>
              </div>
              <div></div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="mt-2">
              <div className="p-6 rounded-2xl column-1 h-full text-center">
                <div className="d-flex justify-content-start">
                  <img src={Vector} alt="" />
                </div>
                <div className="d-flex justify-content-end">
                  <h2 className="num-font">03</h2>
                </div>
                <div className="d-flex justify-content-start">
                  <p className="text-lg font-semibold text-gradient">
                    Future-Proof Technology
                  </p>
                </div>
                <div className="d-flex align-items-start">
                  <p className=" text-gray-500 text-justify">
                    With AIVolveX, you're not just investing in today's
                    solutions; you're investing in the future. Our technology is
                    constantly evolving to keep pace with the latest
                    advancements in AI, ensuring your business stays ahead of
                    the curve.{" "}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
}
export default Section3;
