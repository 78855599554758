import { Button, Form, Input, Col, Row, message, Checkbox } from "antd";
import { useState } from "react";

const ContactForm = () => {
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);


  const onFinish = (values) => {
    setLoader(true);
    const fullname = values.fullname;
    const email_id = values.emailaddress;
    const job_title = values.jobtitle;
    const message_content = values.message;
    const organization = values.organizationname;
    const phone_number = values.phonenumber;
    const myHeaders = new Headers();
    myHeaders.append("x-api-key", "da2-vhjjnmmbinepdeo2nc36sroq6i");
    myHeaders.append("Content-Type", "application/json");

    const graphql = JSON.stringify({
      query:
        'query MyQuery {\r\n  website_email_trigger(email_id: "' +
        email_id +
        '", job_title: "' +
        job_title +
        '", event_type: "website_contact", members_name: "' +
        fullname +
        '", message: "' +
        message_content +
        '", organization: "' +
        organization +
        '", phone_number: "' +
        phone_number +
        '")\r\n}',
      variables: {},
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: graphql,
      redirect: "follow",
    };

    fetch(
      "https://caqt4znsszh73kqzimjn22eexu.appsync-api.ap-south-1.amazonaws.com/graphql",
      requestOptions
    )
      .then((response) => {
        setLoader(false);
      })
      .then((result) => {
        form.resetFields();
        message.success(
          "Thank you for sharing your details! We'll be in touch soon"
        );
        setLoader(false);

      })
      .catch((error) => {
        setLoader(false);
      });


    }
   





  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Form
        name="basic"
        layout="vertical"
        labelCol={{
          span: 23,
        }}
        wrapperCol={{
          span: 23,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-center">
            <label className="form-title">Sign Up Now</label>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Full Name"
              name="fullname"
              rules={[
                {
                  required: true,
                  message: "Please input your Full Name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Email Address"
              name="emailaddress"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please input your valid Email Address!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Phone Number"
              name="phonenumber"
              rules={[
                {
                  required: true,
                  message: "Please input your Phone Number!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Job Title"
              name="jobtitle"
              rules={[
                {
                  required: true,
                  message: "Please input your Job Title!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label="Organization Name"
              name="organizationname"
              rules={[
                {
                  required: true,
                  message: "Please input your Organization Name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label="Message"
              name="message"
              rules={[
                {
                  required: true,
                  message: "Please input your Message!",
                },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="plicy"
              // wrapperCol={{
              //   offset: 8,
              //   span: 16,
              // }}
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            "Please check the box to indicate that you agree to the policy."
                          )
                        ),
                },
              ]}
            >
              <Checkbox>
                I agree to the{" "}
                <a href="https://www.aivolvex.com/policy" target="_blank">
                  Privacy Policy
                </a>
                .{" "}
              </Checkbox>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              wrapperCol={{
                span: 24,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                className="btn-banner-form"
                loading={loader}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ContactForm;
