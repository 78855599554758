import React from "react";
import { Col, Row } from "antd";
import { Icon } from "@iconify/react";

function Section2() {
  return (
    <>
      <Col span={24} className="">
        <div class=" mt-4 container">
          <h2 class="heading-text text-center ">
            Leading Global Brands Are Raising the Bar for<br></br>
            <span class="text-gradient ">Customer Experience</span>
            <span class="heading-text"> with </span>
            <span class="text-gradient">AIVolveX</span>
          </h2>

          <p class="subtitle-text text-sm md:text-base p-2 mt-2">
            At AIVolveX, we believe in the transformative power of AI to drive
            meaningful <br></br>connections between businesses and their
            customers.
          </p>
        </div>
      </Col>

      <Row className="d-flex justify-content-center container text-center">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Row>
            <Col span={12}>
              <div className="mb-4">
                <div className="text-3xl sm:text-5xl md:text-7xl font-bold text-gradient">
                  5X
                </div>
                <div className="font-light text-sm md:text-base fiveX-num">
                  Boost in support team capacity
                </div>
              </div>
              <div>
                <div className="text-3xl sm:text-5xl md:text-7xl font-bold text-gradient">
                  70%
                </div>
                <div className="font-light text-sm md:text-base fiveX-num">
                  Resolution rate
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="mb-4">
                <div className="text-3xl sm:text-5xl md:text-7xl font-bold text-gradient">
                  40%
                </div>
                <div className="font-light text-sm md:text-base fiveX-num">
                  Reduction in support costs
                </div>
              </div>
              <div className="mobile-margin">
                <div className="text-3xl sm:text-5xl md:text-7xl font-bold text-gradient">
                  20%
                </div>
                <div className="font-light text-sm md:text-base fiveX-num">
                  Increase in CSAT score
                </div>
              </div>
            </Col>
          </Row>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="d-flex align-items-start text-start"
        >
          <div>
            <h5 className="Unveiling-cs">
              Unveiling the Impact of{" "}
              <span className="text-gradient">AIVolveX</span>
            </h5>

            <p className="margin-trans">
              At AIVolveX, we believe in the transformative power of AI
              <br />
              to drive meaningful connections between businesses and their
              customers. Founded on the principles of innovation and excellence,
              we're on a mission to revolutionize customer engagement through
              Generative AI technology.
            </p>
            <div>
              <div className="d-flex justify-content-start p-3 learn-more ">
                <span className="gradient-icon">Learn More</span>
                <span className="p-1 gradient-icon">
                  <Icon icon="tabler:arrow-right" />
                </span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default Section2;
