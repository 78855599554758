import { memo } from "react";
import { Document, Page } from "react-pdf";
import brochure_1 from "../assets/brochure/13.jpg";

const Brochure = () => {
  // const brochure_pdf = "http://localhost:3001/brochure/AiVolveXBrochure.pdf";
  return (
    // <div className="mail_style">

    <div>
      {/* <h2>PDF Viewer</h2> */}
      {/* <Document file={brochure_pdf}>
        <Page pageNumber={1} />
      </Document> */}
      <embed
        src="./AiVolveXBrochure.pdf"
        type="application/pdf"
        width="100%"
        style={{ height: "100vh" }}
        // height="100vh"
      />

<embed
        src="./AiVolveXBrochurepdf.pdf"
        type="application/pdf"
        width="100%"
        style={{ height: "100vh" }}
        // height="100vh"
      />
      {/* <img
        src={brochure_1}
        // className="image_style"
        alt="brochure"
        width="100%"
        height="100%"
      /> */}
      {/* <img
        src={brochure_2}
        className="image_style"
        alt="brochure"
        width="100%"
        height="100%"
      />
      <img
        src={brochure_3}
        className="image_style"
        alt="brochure"
        width="100%"
        height="100%"
      />
      <img
        src={brochure_4}
        className="image_style"
        alt="brochure"
        width="100%"
        height="100%"
      />
      <img
        src={brochure_5}
        className="image_style"
        alt="brochure"
        width="100%"
        height="100%"
      />
      <img
        src={brochure_6}
        className="image_style"
        alt="brochure"
        width="100%"
        height="100%"
      /> */}
      {/* <iframe
        title="PDF Viewer"
        src={pdfUrl}
        width="100%"
        height="100%"
        frameBorder="0"
        allowFullScreen
      ></iframe> */}
      {/* <embed
        src={pdfUrl}
        type="application/pdf"
        width="100%"
        height="100%" // Setting embed height to 100%
      /> */}
    </div>
  );
};

// const PDFViewer = ({ pdfUrl }) => {
//   return (
//     <div>
//       <h2>PDF Viewer</h2>
//       <Document file={pdfUrl}>
//         <Page pageNumber={1} />
//       </Document>
//     </div>
//   );
// };

export default memo(Brochure);
